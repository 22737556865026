import "./index.css";

import React from "react";

import useWindowDimensions from "../../utils/customHooks/useWindowDimensions";

import { useNavigate, useSearchParams } from "react-router-dom";

import axios from "../../utils/axios";

import { useContext, useState, useEffect, useRef } from "react";

import { SessionContext } from "../../components/UserContext";

import { toast } from "react-toastify";

import JSEncrypt from "jsencrypt";

import { motion } from "framer-motion"

import { Buffer } from 'buffer';


function Splash() {

  const { width } = useWindowDimensions();

  const [decodedArr, setDecodedArr] = useState({});

  const navigate = useNavigate();

  const [isChecked, setIsChecked] = useState(false);

  const dataFetchedRef = useRef(false);


  const [searchParams] = useSearchParams();

  const { state, updateState } = useContext(SessionContext);


  function handleLogin(info) {

    if (isChecked) return;

    setIsChecked(true);

    const headers = {

      "Access-Control-Allow-Origin": "*",

      "Content-Type": "application/json",

    };

    const login = "login/vouch365";

    const data = {

      username: info.username,

      phone: info.phone,

    };

    axios

      .post(login, data, headers)

      .then((response) => {

        if (response.data.status === true) {

          var usrInfo = response.data.data.user;

          var constants = response.data.data.user.appConstants;

          toast.success("You have successfully logged in!");

          updateState({

            user: usrInfo,

            loggedIn: true,

            token: response.data.data.token,

            appConstants: constants,

          });

          navigate("/");

        } else {

          toast.error(response.data.message);

        }

      })

      .catch((error) => {

        const msg =

          "Dear Customer, to use this functionality, please buy the Vouch 365 Package first from the Packages section.";

        toast.error(msg);

        document.getElementById("error_msg").inner = msg;

        console.error("There was an error!", error);

      });

  }


  useEffect(() => {

    if (dataFetchedRef.current) return;

    dataFetchedRef.current = true;

    let encodedParam = searchParams.get("q");

    //if encodedparam is empty string and with no whitespaces  use string isnull, show toast.error and return

    if (encodedParam == null || encodedParam == "") {

      const msg =

        "Dear Customer, to use this functionality, please buy the Vouch 365 Package first from the Packages section.";

      toast.error(msg);

      document.getElementById("error_msg").innerHTML = msg;

      return;

    }


    try {

      // const privateKey =

      //   "MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCSN5fn0iGvwcraXY5h93KwnhOaA0QrtzxiXC6RFTEl00jAYZfcfSvLeglcQxzePgT8+/y/4ZwaSTwEMEYAQ8384mFLoeeFUUZdAF9TxChYQrLvlG1kehOi0ZzUq0jGcuL6jH5fD5DHSZ3sZJS3adLd3rmt7q2sWzL85oertNfQevokLBUordtwjopjJ/1csZOH+2gp7HBq1nOdreMCZFG0K2KZhkHh+ZybYl5KtRBp9TjuCCCDySZsz/ZnzjGYrwYa42KH6I/c6SB+NQGeqbLxM8+qBmE2LBFxT/uFRVxX2faROOdwfDVfBZYPChmn3e7pdrCrBFa4sMvhqznG+7LVAgMBAAECggEAJhxrcbcppKAEeapNBGf08ugg/9G+IQ7fpFuWn6ZAazWJDuvhI01U7vJeIjgz/BF6R15+4JyzjX5tMEJM3KDPvgxbg9UtfWhTyMXiEvlb8rVf4mZzpO7U02mDUUNb8q3bT6bzAWATQn6DtSv6P1xL5By7lQRTLEuRHK4RysWWQvaCI9x7H9cZYKAFU8bfilAft4F3Bm4yTMvQrXQuFkCow0NQBuAKxaTXFZ0CXqDP+sEHCZig9FPqxDnuMNgYqeNokfj6kyS6YJpvmOKjga9anKSQb8+4tipc9d7iHnoqYa5+9sGRnCcoP1dXmisGO9oiCh0mCtr6aZLW+4SrNSDS+QKBgQD/F3YVw45kdkMIY8whptEOYbrWFQhdVdXABA2gVsMNOC147/rrppSQ8lAnYmmSifKx3idd+wjZ5yAKkkrnOmO6+lBHxcnz1gDSHWz86jxBB0Nu2D2sMyK/Z2uhBdkS6RliF3ijbdg5UmB9e/lf4vhKrw1K7qRmCUZ0GoIehCxjqwKBgQCSvOIiGVj+q4E4Yvx+gaOVnDVh+fTwPQuHnE+MweOF06l1kuI0P0wJft4fHT1V1mdFK/9qAmYX8oJLxCd13Aeyg34Vz5aCSkZUJJ8wbj9m4j+NBiywfNSzaurMQVCE129fjeo49FT4NJM/dPaF7rvkAdWwEamvVe1RHluCpCHDfwKBgE4kdbv9B+WmhKpRR/sXCvuI3J8wGjvdS3lixHnVmjK2fteJTChcAy7Ty9IEx6poL7xk/VJA0FlP//S9E8z5iguBGo6h/FeBuwailPx1FLgm7XVLMLlLgN15ZPMSw4iTd38hCYem4pHMtlo6XA4PjVfzf/LwX9QSC08Nv8ZxXte5AoGAd5cBJZFK+qOEORRTfDbjv3cD+sngWjsmPmuiWbFbYSJUJcKEINjFe6euF+WYq3CF0dY934P8+N9oWZ+QGXotu8t24wfAPoeSMdl2892mRo92A9VYbq4OG/uzSKyiluGNn27lyW04IuGWcE0+7YDYGMd2BH0gsglx1H7NTfe+nLkCgYBipzyTDVxTGCfCGaTvNXUNvCTlkhj8B3piRziXM6KDjXPoQ9hY7qO0uP+7cpU4DPKvWVz7/Bl/MBlo41hW9MHwcFf/HfaC6jFfCWXg1u0MBfDGt+19hthK+0QZZXPrToXepdIcMH6PjrdDLdyIKkdXho/u1bvIKjEQgMBBPylImA==";

      const privateKey =

        "MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDbccfzfVv9xQ7pMYAOIcFSZC724ULLTHlbuq1OLHhjARe7o6jlxgbNzB78nNqB+SxoX0c2QP8+Oqufp9zfi5s4J+Cj/k0T32gEEXwzDEcLnk8IXq3/bkk+rP/g+sJSwxAD7JyqXN8mryLiABeWoAe47D2bq+Ulzlmb99L3eBuGxhflH2Fg4yjHwzU1yZdQY99WCESXAvKMO9IsO43acrnDDWrfj4//4yrETxXwZrlgnJIqSBEl685VfFotP7S8SbPfva7Drh+6ZQaCqPYcvOrrQHW78WfOvcfHkEw6JHdj52HQq6NL3G/RZaBwB4dtlv5HnbZA1xlT6w75S1CifPKbAgMBAAECggEABEyiYj7egoZN2HvDyoMWyopz8RpQuLLy3b02kAWHBkmLMX3vXTEYCQgqw0A/d7RuDk1vk7yKQ6namvIq/aY6kEsXt57P28fQveOpz0Y6Thv36G4ScqKyhKvXB41iKUTxF2KXXy8aoCNmR49A5w6TmCfovRgNEUSNtOSSGY8c2vMVUyAnlt0r3BdpPprV1eW2DVyhzBqEKaIWuTBhvCIo8pLXjP9JHuelzTJ7d5DuNiVogdnlErN76b9iWX9DamasezWFsXNCqs4Pe4i3Jk/waMJNbImhJxMTL3Sa4NM/GqOou5l9Zma569ivW+jjK/L0x8UIU9sDyYIrjm97o7hD4QKBgQDuhTdyYdSgecNVOtDEa9wGMPR+p6x/V21P8UsScxKuDeE+g2Qc4nIy9OCkt1raT2xz8jrk+U6UnlPK/dh67YuWyO9v2JlXviVRdrY8JhBmt68XIvFJonqFN6iWgdRDNWpvjbM4FgVc1x7s8ziwHgrVIt8kkmXhNEzSc/rj0b2/RQKBgQDrhrBdP4DUnRkg+VxsqmcpFJeHlSgkOSSRhuGLQFF5nhY3jqULzL1jTi22MpZIhbMthO5IxAeR/kbYYVMbK6JsdueWDS8TVjje/yYHhAOzHX6Xc7TQrJ25rwy6OEnWf0hvnj2aSPSEgiFgbZsvqtYu+umdd4ubtSjKWzzfBJqYXwKBgQDXwWVAcEI3zyzkIcnKg46JgysGjoG4xsq4r5mq3LCfBSsksxGSFzwZ3+x80ua553JBB3JCwUlVokpFqo1x1QKZqLT9xT4gYn27TNjXW88mOy2EjjUY2IJfktNLIbFyAhV/RvxA1ZqQOn9c8z3qAaLm3R5gJJVHu1lqXUMrZQRwyQKBgEoamqr8LAC5XQqu6pGWG9clD3gtSe/7urG/Cn/RwNDPGJZqXyuTQ5NXKbLFv9sZlgKgbIighPE+W/QAyzElTXlq//qKM2GWI1amWMTMmI1mnknwsddmOEoP9aUxyKmgap4T37YHdkeq2e2JDua0jadCcvaEgnx/R1JD2qcgv7NpAoGBAOAz0I9oZjILyWhBg9vYY9wJQM5sKNrhIiCP73o+twDZksf+QKIY0x6MojEwGn+wzBUx/AXG+J3RsK7b880nZla+SN5YBE5bQUYkldfVEfcP+vYttO3Pnm452hL98V6m73hPDvAyrDT3t5vn/4PRH1+ZLUnliqHx/t/yIUJPhQK2";

      var decrypt = new JSEncrypt();

      decrypt.setPrivateKey(privateKey);


      const pubKey =

        "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA23HH831b/cUO6TGADiHBUmQu9uFCy0x5W7qtTix4YwEXu6Oo5cYGzcwe/JzagfksaF9HNkD/Pjqrn6fc34ubOCfgo/5NE99oBBF8MwxHC55PCF6t/25JPqz/4PrCUsMQA+ycqlzfJq8i4gAXlqAHuOw9m6vlJc5Zm/fS93gbhsYX5R9hYOMox8M1NcmXUGPfVghElwLyjDvSLDuN2nK5ww1q34+P/+MqxE8V8Ga5YJySKkgRJevOVXxaLT+0vEmz372uw64fumUGgqj2HLzq60B1u/Fnzr3Hx5BMOiR3Y+dh0KujS9xv0WWgcAeHbZb+R522QNcZU+sO+UtQonzymwIDAQAB";

      // const pubKey =

      //   "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAkjeX59Ihr8HK2l2OYfdysJ4TmgNEK7c8YlwukRUxJdNIwGGX3H0ry3oJXEMc3j4E/Pv8v+GcGkk8BDBGAEPN/OJhS6HnhVFGXQBfU8QoWEKy75RtZHoTotGc1KtIxnLi+ox+Xw+Qx0md7GSUt2nS3d65re6trFsy/OaHq7TX0Hr6JCwVKK3bcI6KYyf9XLGTh/toKexwatZzna3jAmRRtCtimYZB4fmcm2JeSrUQafU47gggg8kmbM/2Z84xmK8GGuNih+iP3OkgfjUBnqmy8TPPqgZhNiwRcU/7hUVcV9n2kTjncHw1XwWWDwoZp93u6XawqwRWuLDL4as5xvuy1QIDAQAB";

      var test = {

        cnic: "4212145878965",

        phone: "03213853365",

      };


      var enc = new JSEncrypt();

      enc.setPublicKey(pubKey);

      ////console.log(test);

      // console.log("Encrypted: " + encodeURIComponent(enc.encrypt(JSON.stringify(test))));

      var result = decrypt.decrypt(Buffer.from(decodeURI(encodedParam), 'base64').toString());

      const paramsArray = result.split("&"); // Split into key-value pairs

      const jsonObject = {};


      paramsArray.forEach((param) => {

        const [key, value] = param.split("=");

        jsonObject[key] = value;

      });


      //var info = JSON.parse(result);

//      console.log(JSON.parse(Object.keys(jsonObject)[0]), 'mmm')

      handleLogin(JSON.parse(Object.keys(jsonObject)[0]));

    } catch (e) {

      console.log(e);

      const msg =

        "Dear Customer, to use this functionality, please buy the Vouch 365 Package first from the Packages section.";

      toast.error(msg);

      document.getElementById("error_msg").innerHTML = msg;

    }

  }, []);


  return (

    <motion.div

      initial={{ y: 300, opacity: 0 }}

      animate={{ y: 0, opacity: 1 }}

      transition={{ duration: 2 }}>

      <div className="model">

        <div className="model content">

          <div className="container">

            <div className="pic">

              <img src="/images/logo.png" alt="logo" />

            </div>

            <h2 style={{ margin: 0 }}>Welcome to Vouch365</h2>

            <sub>

              <b>Celebrate the journey with us!</b>

            </sub>

            <br />

            <br />

            <br />

            <br />

            <img

              src="/images/intro.jpg"

              alt="splash"

              className="SplashImage"

            // style={{ width: width }}

            />

            <br />

            <br />

            <sub id="error_msg">

              Please wait, while we are verifying your request...

            </sub>

          </div>

        </div>

      </div>

    </motion.div>

  );

}


export default Splash;
